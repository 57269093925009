.header {
  display: flex;
  justify-content: space-between;
  position: relative;
  border-radius: 5px 5px 0 0;
  padding: 10px;
  align-items: center;
  box-shadow: 0 2px 10px -1px rgba(0, 0, 0, 0.2);
}

.addIcon {
  width: 30px;
  height: 30px;
}

.dateWrapper {
  display: flex;
}

.date {
  display: flex;
  margin-right: 20px;
  & div {
    margin: 0 10px;
    min-width: 125px;
    text-align: center;
  }
}

.arrowLeft {
  width: 10px;
  height: 20px;
  cursor: pointer;
}

.arrowRight {
  width: 10px;
  height: 20px;
  cursor: pointer;
  transform: rotate(-180deg);
}

.datePicker {
  width: 20px;
  height: 20px;
}

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.background {
  width: 100%;
  height: 100%;
  background: rgba(59, 89, 152, 0.6);
  position: absolute;
  top: 0;
  left: 0;
}

.content {
  width: 30%;
  box-sizing: border-box;
  padding: 24px;
  border-radius: 10px;
  background: #fff;
  z-index: 1;
}

.pickerHeader {
  display: flex;
  justify-content: space-evenly;
}

//
//.wrapper {
//  justify-content: center;
//  text-align: center;
//  top: 15%;
//  left: 75%;
//  width: 100px;
//  align-items: center;
//  display: flex;
//  height: 100px;
//  background-color: darkolivegreen;
//  position: absolute;
//  z-index: 1;
//}
//
//.background {
//  position: fixed;
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 0;
//  background-color: black;
//  opacity: 0.5;
//}

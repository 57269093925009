.form {
  width: 30%;
  box-sizing: border-box;
  padding: 24px;
  border-radius: 10px;
  background: #fff;
  z-index: 1;
}

.dateTimeWrapper {
  display: flex;
  justify-content: space-between;
  & > * {
    min-width: 30% !important;
    max-width: 30% !important;
  }
  & > div {
    & input {
      min-height: 45px;
    }
  }
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  & div {
    background-color: antiquewhite;
    padding: 5px;
    margin-right: 10px;
  }
}

.buttonActive {
  border: none;
  background-color: cornflowerblue;
  padding: 10px 40px;
}

.buttonDisable {
  @extend .buttonActive;
  opacity: 0.3;
}

.createEditInfo {
  margin: 15px 0;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  & span:first-child {
    margin-bottom: 5px;
  }
}
